<template>
		<div class="shadow-2 p-3 h-full flex flex-column surface-card" align="left" style="border-radius: 6px">
			<div class="text-900 font-medium text-xl mb-2">
				Pricing
				<a href="https://youtu.be/IYD7WKzx-yc" target="_blank">
					<Tag class="mr-2" severity="info" style="margin-left: 20px;" value="Demo video"></Tag>
				</a>
				<a href="https://www.libertynlp.com/index.php/2022/06/24/how-to-import-and-use-pdf-js-in-a-new-project/"
					target="_blank">
					<Tag class="mr-2" severity="success" style="margin-left: 20px;" value="Usage"></Tag>
				</a>
			</div>
			<div class="text-600" style="font-size: 12px;">pay and contact <span
					style="font-weight: 700;">weichaoxu1998@gmail.com</span> to get code</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center">
				<span class="font-bold text-2xl text-900">$20</span>
				<span class="ml-2 font-medium text-600">encrypted code</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<div class="flex align-items-center">
				<span class="font-bold text-2xl text-900">$200</span>
				<span class="ml-2 font-medium text-600">source code</span>
			</div>
			<hr class="my-3 mx-0 border-top-1 border-none surface-border" />
			<ul class="list-none p-0 m-0 flex-grow-1">
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>pdfjs usage guide</span>
				</li>
				<li class="flex align-items-center mb-3">
					<i class="pi pi-check-circle text-green-500 mr-2"></i>
					<span>free update</span>
				</li>
			</ul>
			<hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
			<div class="text-600" style="font-size: 12px;margin-bottom: 10px;">*All rights reserved, secondary
				distribution and sale are prohibited.</div>
		
			<div>
				<a :href="pay_src" target="_blank"><Button title='pay me with PayPal and contact me to get code'
						label="Buy" icon="pi pi-paypal" style="width: 35%;float: left;"></Button></a>
				<Button style="width: 60%;float: right;" @click="toggle_wechat_pay" title='使用中国大陆版微信扫码支付' label="微信支付(特惠)"
					class="p-button-success mr-2 mb-2"></Button>
				<OverlayPanel ref="wechat_pay" appendTo="body" :showCloseIcon="true">
						<div style="width: 400px;" align="center">
							<h5>中国大陆地区微信支付</h5>
							<Button label="加密版 ￥99" style="width: 350px;" class="p-button-info mb-3" />
							<Button label="源码版 ￥999" style="width: 350px;" class="p-button-success mb-3" />
							<img src="images/wechat_pay.jpg" style='width: 350px;' alt="" />
						</div>
				</OverlayPanel>
			</div>
		</div>
</template>

<script>
	//从相对路径引入 NetWorkService.js
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				pay_src: 'https://paypal.me/weichaoxu?country.x=C2&locale.x=zh_XC',
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {
			toggle_wechat_pay(event) {
				this.$refs.wechat_pay.toggle(event);
			},
		},
	}
</script>

<style scoped lang="scss">
</style>
